import React from 'react'
import Header from '../partials/header'
import Footer from '../partials/footer'
import Partner from '../components/partner'
import borderheadling from "../images/headlineborder.png";
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const partner = () => {
    return (
        <main>
            <Header/>
            <AruzySeo title={"Partner with Aruzy"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'PARTNER WITH ARUZY'}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <p>Need to get noticed? ARUZY are a member’s only lifestyle brand for people looking to discover new things.</p>
                                        <p>
                                            We work with partners to bring experiences and offers to
                                            our
                                            members. By becoming an ARUZY partner you’ll be putting
                                            your brand
                                            in front of a highly motivated audience. We’re talking
                                            about
                                            people who want to hear from you, that need to try the
                                            latest
                                            thing, and are ready to become your biggest fans.
                                        </p>
                                    </div>
                                    <div className='col-md-6 mobiledisplay'>
                                        <div className="bgpara">
                                            <p className="pinkcolor font-600">Why choose us!</p>
                                            <h3 className="bluecolor">What we offer our partners?</h3>
                                            <p className="text-center">
                                                As well as placing your brand in front of eager eyes, we
                                                provide
                                                invaluable marketing in other ways. Our partner services
                                                include
                                                marketing and event collaboration.
                                            </p>
                                            <p className="text-center">
                                                As a member’s only platform, we have a meaningful
                                                connection with
                                                the people within our community. That’s because we offer
                                                them more
                                                than just discounted days out. Even though things like
                                                unusual
                                                experience days and a 2 for 1 day out sell, it’s our
                                                extra
                                                services that will give your brand the edge.
                                            </p>
                                            <p className="text-center">
                                                Our bundles make planning a full day out easy, and our
                                                website is
                                                built to simplify the search for the perfect activity.
                                                But it’s
                                                our ARUZY events with curated partners that our members
                                                remember
                                                best.
                                            </p>
                                            <p className="text-center">If you want to share your brand with our members, simply fill out the form below now.</p>
                                        </div>
                                    </div>
                                    <Partner/>
                                </div>
                                <div className='col-md-6 mobilenone'>
                                    <div className="bgpara">
                                        <p className="pinkcolor font-600">Why choose us!</p>
                                        <h3 className="bluecolor">What we offer our partners?</h3>
                                        <p className="text-center">
                                            As well as placing your brand in front of eager eyes, we provide
                                            invaluable marketing in other ways. Our partner services include marketing and event collaboration.
                                        </p>
                                        <p className="text-center">
                                            As a member’s only platform, we have a meaningful connection with the people within our community. That’s because we offer
                                            them more than just discounted days out. Even though things like unusual
                                            experience days and a 2 for 1 day out sell, it’s our extra services that will give your brand the edge.
                                        </p>
                                        <p className="text-center">
                                            Our bundles make planning a full day out easy, and our website is built to simplify the search for the perfect activity.
                                            But it’s our ARUZY events with curated partners that our members remember best.
                                        </p>
                                        <p className="text-center">If you want to share your brand with our members, simply fill out the form below now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default partner
